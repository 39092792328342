import toastr from 'toastr'
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import api from '@src/lib/services/api'
import { getToken, clearToken } from '@src/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { data, token, displayMsg } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (data.email && (data.password || data.passcode)) {
        try {
          let result = yield call(api.login, data)
          if (result && result.data && result.data.data && result.data.data.authUser && result.data.data.authUser.token) {
            if (result.data.data.authUser.approved || (result.data.data.authUser.tour && result.data.data.authUser.tour !== '3')) {
              yield put({
                type: actions.LOGIN_SUCCESS,
                token: result.data.data.authUser.token,
                profile: 'Profile UserId' + result.data.data.authUser._id,
              });
              if(result.data.data.authUser.approved && displayMsg) toastr.success('Login successfully')
            } else {
              if(displayMsg) toastr.info(`You are not approved yet... please try again later`)
              yield put({ type: actions.LOGIN_ERROR });
            }
          } else {
            if(displayMsg) toastr.error(`User not found, or invalid ${data.password ? 'password' : 'passcode'}`)
            yield put({ type: actions.LOGIN_ERROR });
          }
        } catch (e) {
          if(displayMsg) toastr.error(`User not found, or invalid ${data.password ? 'password' : 'passcode'}`)
          yield put({ type: actions.LOGIN_ERROR });
        }
      } else {
        toastr.error('Invalid input')
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*({ payload }) {
    const { isLogoutAPI } = payload
    if(isLogoutAPI) yield call(api.logout);
    yield clearToken();
    history.push('/login');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
