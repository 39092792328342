import axios from 'axios'
import query from '@src/lib/services/query';
import mutation from '@src/lib/services/mutation';
import { getToken, clearToken } from '@src/lib/helpers/utility';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({ forceRefresh: true });

const webAPIHost = process.env.REACT_APP_WEB_API_HOST

function getAPIHeader (token) {
	return {
		headers: {
			'authorization': token
		}
	}
}

function unauthorizedHandleFn () {
	clearToken()
	history.push('/login');
}

const Api = {
	// Auth
	login: async (data) => {
		return axios.post(webAPIHost, { query: query.AUTH_USER, variables: data })
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	socialLogin: async (data, cb) => {
		return axios.post(webAPIHost, { query: query.SOCIAL_LOGIN, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	logout: async () => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.LOGOUT }, options)
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	registerUser: (data, cb) => {
		return axios.post(webAPIHost, { query: mutation.ADD_USER, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getUserDetails: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_USER_DETAILS }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	updateUser: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.UPDATE_USER, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	changePassword: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.CHANGE_PASSWORD, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	changePasscode: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.CHANGE_PASSCODE, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getStatisticsData: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_STASTICS_DATA , variables: data}, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			console.log(error.response)
			cb(error, null)
		})
	},
	getMuscleGroups: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_MUSCLE_GROUPS }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getExercises: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_EXERCISES, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getExerciseDetails: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_EXERCISE_DETAIL, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getMeasurements: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_MEASUREMENTS, variables: {}}, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getNotifications: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_NOTIFICATIONS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getUnreadNotificationCount: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.UNREAD_NOTIFICATION_COUNT }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	readNotifications: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.READ_NOTIFICATIONS }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getUserMeasurements: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_USER_MEASUREMENTS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	addUserMeasurement: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.ADD_USER_MEASUREMENT, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getLeaderBoardExercises: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_LEADER_BOARD_EXERCISES }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getLeaderBoardData: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_LEADER_BOARD_DATA, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getSessions: (cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_SESSIONS }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getWorkoutExercises: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_WORKOUT_EXERCISES, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	addWorkout: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.ADD_WORKOUT, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getWorkouts: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_WORKOUTS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getWorkoutDetails: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_WORKOUT_DETAILS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getFeeds: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_FEEDS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getFeedDetails: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_FEED_DETAIL, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	addFeedComment: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.ADD_FEED_COMMENT, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	likeUserFeed: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: mutation.LIKE_USER_FEED, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getActivities: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_ACTIVITIES, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getUserWorkoutExercises: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_USER_WORKOUT_EXERCISES, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getExercisesStatistics: (data, cb) => {
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		return axios.post(webAPIHost, { query: query.GET_EXERCISES_STATISTICS, variables: data }, options)
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			console.log(error.response)
			cb(error, null)
		})
	},
	forgotPasswordEmail: (data, cb) => {
		return axios.post(webAPIHost, { query: mutation.FORGOT_PASSWORD_EMAIL, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getSettingDetails: async (data) => {
		return axios.post(webAPIHost, { query: query.GET_SETTING_DETAILS }, {})
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	// Contact request
	// fetchContactUsRequests: (data, cb) => {
	// 	const url = webAPIHost + '/api/getContactUsRequests';
	// 	const token = getToken().get('idToken');
	// 	const options = getAPIHeader(token);
	// 	options.data = data
	// 	axios.post(url, options)
	//   	.then((result) => { cb(null, result) })
	//   	.catch((error) => { cb(error, null) })
	// },
	// Users
	fetchUsers: (data, cb) => {
		const url = webAPIHost + '/api/getUsers';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveUser: (data, cb) => {
		const url = webAPIHost + '/api/saveUser';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteUser: (id, cb) => {
		const url = webAPIHost + '/api/deleteUser/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Partners
	fetchPartners: (data, cb) => {
		const url = webAPIHost + '/api/getPartners';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	savePartner: (data, cb) => {
		const url = webAPIHost + '/api/savePartner';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updatePartner: (data, cb) => {
		const url = webAPIHost + '/api/updatePartner';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deletePartner: (id, cb) => {
		const url = webAPIHost + '/api/deletePartner/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Images
	fetchImages: (data, cb) => {
		const url = webAPIHost + '/api/getImages';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getImage: (data, cb) => {
		const url = webAPIHost + '/api/getImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveImage: (data, cb) => {
		const url = webAPIHost + '/api/saveImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateImage: (data, cb) => {
		const url = webAPIHost + '/api/updateImage';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteImage: (id, cb) => {
		const url = webAPIHost + '/api/deleteImage/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Blogs
	fetchBlogs: (data, cb) => {
		const url = webAPIHost + '/api/getBlogs';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getBlog: (data, cb) => {
		const url = webAPIHost + '/api/getBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveBlog: (data, cb) => {
		const url = webAPIHost + '/api/saveBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateBlog: (data, cb) => {
		const url = webAPIHost + '/api/updateBlog';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteBlog: (id, cb) => {
		const url = webAPIHost + '/api/deleteBlog/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Podcast
	fetchPodcasts: (data, cb) => {
		const url = webAPIHost + '/api/getPodcasts';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getPodcast: (data, cb) => {
		const url = webAPIHost + '/api/getPodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	savePodcast: (data, cb) => {
		const url = webAPIHost + '/api/savePodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updatePodcast: (data, cb) => {
		const url = webAPIHost + '/api/updatePodcast';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deletePodcast: (id, cb) => {
		const url = webAPIHost + '/api/deletePodcast/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Testimonials
	fetchTestimonials: (data, cb) => {
		const url = webAPIHost + '/api/getTestimonials';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveTestimonial: (data, cb) => {
		const url = webAPIHost + '/api/saveTestimonial';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateTestimonial: (data, cb) => {
		const url = webAPIHost + '/api/updateTestimonial';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteTestimonial: (id, cb) => {
		const url = webAPIHost + '/api/deleteTestimonial/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// SeoTags
	fetchSeoTags: (data, cb) => {
		const url = webAPIHost + '/api/getSeoTags';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/getSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => {
				if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
				cb(error, null)
			})
		} catch (error) {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		}
	},
	saveSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/saveSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateSeoTag: (data, cb) => {
		const url = webAPIHost + '/api/updateSeoTag';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteSeoTag: (id, cb) => {
		const url = webAPIHost + '/api/deleteSeoTag/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Videos
	fetchVideos: (data, cb) => {
		const url = webAPIHost + '/api/getVideos';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	getLinkData: async (data, cb) => {
		const url = webAPIHost + '/api/getLinkData';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		if (data) options['params'] = data
		await axios.get(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	saveVideo: (data, cb) => {
		const url = webAPIHost + '/api/saveVideo';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.post(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateVideo: (data, cb) => {
		const url = webAPIHost + '/api/updateVideo';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	updateVideoOrders: (data, cb) => {
		const url = webAPIHost + '/api/updateVideoOrders';
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.put(url, data, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	deleteVideo: (id, cb) => {
		const url = webAPIHost + '/api/deleteVideo/' + id;
		const token = getToken().get('idToken');
		const options = getAPIHeader(token);
		axios.delete(url, options)
		.then((result) => { cb(null, result) })
		.catch((error) => {
			if (error && error.response && (error.response.status === 401)) unauthorizedHandleFn()
			cb(error, null)
		})
	},
	// Get Data
	webTestimonials: (data, cb) => {
		const url = webAPIHost + '/public/getTestimonials';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webPartners: (data, cb) => {
		const url = webAPIHost + '/public/getPartners';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webImages: (data, cb) => {
		const url = webAPIHost + '/public/getImages';
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webMedia: (data, cb) => {
		const url = webAPIHost + '/public/getMedia';
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	webBlog: (data, cb) => {
		const url = webAPIHost + '/public/getBlog';
		const options = {}
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => { cb(error, null) })
		} catch (e) {
			cb(e, null)
		}
	},
	webPodcast: (data, cb) => {
		const url = webAPIHost + '/public/getPodcast';
		const options = {}
		if (data) options['params'] = data
		try {
			axios.get(url, options)
			.then((result) => { cb(null, result) })
			.catch((error) => { cb(error, null) })
		} catch (e) {
			cb(e, null)
		}
	},
	webSeoTags: (data, cb) => {
		const url = webAPIHost + '/public/getSeoTags';
		data.cp = true
		axios.post(url, data)
		.then((result) => { cb(null, result) })
		.catch((error) => { cb(error, null) })
	},
	addContact: (data, cb) => {
		return axios.post(webAPIHost, { query: mutation.ADD_CONTACT, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getCategories: (data, cb) => {
		return axios.post(webAPIHost, { query: query.GET_CATEGORIES, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getProducts: (data, cb) => {
		return axios.post(webAPIHost, { query: query.GET_PRODUCTS, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getProductDetails: (data, cb) => {
		return axios.post(webAPIHost, { query: query.GET_PRODUCT_DETAIL, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},
	getBanners: (data, cb) => {
		return axios.post(webAPIHost, { query: query.GET_BANNERS, variables: data })
		.then(response => {
			cb(null, response)
		}).catch((error) => {
			cb(error, null)
		})
	},

}

export default Api;
