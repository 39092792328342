const AUTH_USER = `
  query authUser($email: String, $password: String, $passcode: Int) {
    authUser(email: $email, password: $password, passcode: $passcode) {
      _id
      name
      email
      role
      token
      tour
      approved
    }
  }
`;

const SOCIAL_LOGIN = `
  query socialLogin($type: String, $token: String) {
    socialLogin(type: $type, token: $token) {
      _id
      name
      email
      role
      token
      tour
      approved
    }
  }
`;

const GET_USER_DETAILS = `
  query getUserDetails {
    getUserDetails {
      _id
      email
      role
      profile {
        firstName
        lastName
        age
        sex
        image
      }
      measurements {
        updatedAt
        details {
          measurementId
          name
          value
          progress
        }
      }
      tour
      approved
    }
  }
`

const GET_STASTICS_DATA = `
  query getStatisticsData($weightFilter: String, $month: Int, $year: Int, $checkInMonth: Int, $checkInYear: Int) {
    getStatisticsData(weightFilter: $weightFilter, month: $month, year: $year, checkInMonth: $checkInMonth, checkInYear: $checkInYear) {
      gymCheckInData {
        date
    		count
      }
      weightData {
        weightDataList {
          date
          month
          weight
        }
      }
      lastMeasurementDate
      lastCheckInDate
    }
  }
`

const GET_MUSCLE_GROUPS = `
  query getMuscleGroups($limit: Int, $skip: Int, $keyword: String) {
    getMuscleGroups(limit: $limit, skip: $skip, keyword: $keyword) {
      muscleGroups {
        _id
        name
        image
        icon
      }
      total
    }
  }
`

const GET_EXERCISES = `
  query getExercises($limit: Int, $skip: Int, $keyword: String, $search: [String], $muscleGroupIds: [ID]) {
    getExercises(limit: $limit, skip: $skip, keyword: $keyword, search: $search, muscleGroupIds: $muscleGroupIds) {
      exercises {
        _id
        name
        image
        icon
        description
        video
        tags
        muscleGroupId {
          _id
          name
        }
        type
      }
      total
    }
  }
`

const GET_EXERCISE_DETAIL = `
  query getExerciseDetails($exerciseId: ID) {
    getExerciseDetails(exerciseId: $exerciseId) {
      _id
      name
      description
      video
      tags
      muscleGroupId {
        _id
        name
      }
    }
  }
`

const GET_MEASUREMENTS = `
  query getMeasurements($limit: Int, $skip: Int, $keyword: String) {
    getMeasurements(limit: $limit, skip: $skip, keyword: $keyword) {
      measurements {
        _id
        name
        default
      }
      total
      skip
      limit
    }
  }
`

const GET_USER_MEASUREMENTS = `
  query getUserMeasurements($limit: Int, $skip: Int, $keyword: String) {
    getUserMeasurements(limit: $limit, skip: $skip, keyword: $keyword) {
      userMeasurements {
        _id
        userId {
          _id
        }
        measurements {
          measurementId {
            _id
            name
          }
          value
        }
        addedAt
        message
      }
      total
      skip
      limit
    }
  }
`

const GET_NOTIFICATIONS = `
  query getNotifications($limit: Int, $skip: Int, $keyword: String) {
    getNotifications(limit: $limit, skip: $skip, keyword: $keyword) {
      notifications {
        _id
        message
        type
        readby
        creatorId {
          _id
          profile {
            firstName
            lastName
            image
          }
        }
        createdAt
      }
      total
      skip
      limit
    }
  }
`

const UNREAD_NOTIFICATION_COUNT = `
  query unreadNotificationCount {
    unreadNotificationCount
  }
`

const GET_LEADER_BOARD_EXERCISES = `
  query getLeaderBoardExercises {
    getLeaderBoardExercises
  }
`

const GET_LEADER_BOARD_DATA = `
  query getLeaderBoardData($exerciseIds: [ID]) {
    getLeaderBoardData(exerciseIds: $exerciseIds) {
      users {
        userId {
          profile {
            firstName
            lastName
            image
          }
        }
        maxWeight
        minTime
      }
      exerciseId
      name
      icon
      type
    }
  }
`

const GET_WORKOUT_EXERCISES = `
  query getWorkoutExercises($exerciseIds: [ID]) {
    getWorkoutExercises(exerciseIds: $exerciseIds) {
      exerciseId
      maxWeight
      maxReps
      minTime
    }
  }
`

const GET_WORKOUTS = `
  query getWorkouts ($fromDate: Date, $toDate: Date) {
    getWorkouts (fromDate: $fromDate, toDate: $toDate) {
      workoutHistoryData {
        _id
        totalExercises
        sessionId {
          _id
          name
          type
        }
        createdAt
        exercises {
          exerciseId {
            _id
            name
            type
            description
            video
            tags
            image
            icon
            muscleGroupId {
              _id
              name
            }
          }
        }
      }
      userCreatedDate
    }
  }
`

const GET_WORKOUT_DETAILS = `
  query getWorkoutDetails ($workoutId: ID, $workoutDate: Date) {
    getWorkoutDetails (workoutId: $workoutId, workoutDate: $workoutDate) {
      _id
      session
      createdAt
      exercises {
        _id
        name
        muscleGroupName
        image
        type
        totalSets
        totalReps
        totalWeights
        totalMinutes
        createdAt
      }
    }
  }
`
const GET_SESSIONS = `
  query getSessions {
    getSessions {
      sessions {
        _id
        name
        type
      }
    }
  }
`

const GET_FEEDS = `
  query getFeeds($limit: Int, $skip: Int) {
    getFeeds(limit: $limit, skip: $skip) {
      feeds {
        _id
        userId {
          _id
          profile {
            firstName
            lastName
            image
          }
        }
        type
        likes
        message
        createdAt
      }
      total
      skip
      limit
    }
  }
`

const GET_FEED_DETAIL = `
  query getFeedDetails($feedId: ID) {
    getFeedDetails(feedId: $feedId) {
      _id
      userId {
        _id
        profile {
          firstName
          lastName
          image
        }
      }
      type
      likes
      message
      createdAt
      comments {
        userId {
          _id
          profile {
            firstName
            lastName
            image
          }
        }
        text
        createdAt
      }
    }
  }
`
const GET_ACTIVITIES = `
  query getActivities ($skip: Int, $limit: Int) {
    getActivities (skip: $skip, limit: $limit) {
      activities {
        _id
        userId {
          _id
        }
        type
        session
        duration
        createdAt
      }
      total
    }
  }
`

const GET_USER_WORKOUT_EXERCISES = `
  query getUserWorkoutExercises ($month: Int, $year: Int) {
    getUserWorkoutExercises (month: $month, year: $year) {
      userWorkoutExercise {
        _id
        name
      }
      lastStatisticsDate
    }
  }
`

const GET_EXERCISES_STATISTICS = `
  query getExercisesStatistics($exerciseIds: [ID], $month: Int, $year: Int) {
    getExercisesStatistics(exerciseIds: $exerciseIds, month: $month, year: $year) {
      exerciseStatistics {
        _id
        type
        name
        workoutData {
          date
          maxWeight
          minTime
        }
      }
      lastStatisticsDate
    }
  }
`

const GET_SETTING_DETAILS = `
  query getSettingDetails {
		getSettingDetails {
			_id
			name
			email
			address {
				address1
				address2
				city
				country
				zipCode
			}
			primaryLogo
			secondaryLogo
      secondarySmallLogo
			colors {
				primary
				secondary
				tertiary
			}
      privacy
      terms
      mobile
      about {
        text
        mission
        vision
      }
		}
	}
`

const LOGOUT = `
  query logout {
    logout
  }
`

const GET_CATEGORIES = `
  query getCategories ($skip: Int, $limit: Int, $keyword: String) {
    getCategories (skip: $skip, limit: $limit, keyword: $keyword) {
      categories {
        _id
        name
        photos
        description
        visible
      }
      total
    }
  }
`

const GET_PRODUCTS = `
  query getProducts ($skip: Int, $limit: Int, $keyword: String, $categories: [ID], $showInHomePage: Boolean) {
    getProducts (skip: $skip, limit: $limit, keyword: $keyword, categories: $categories, showInHomePage: $showInHomePage) {
      products {
        _id
        name
        categories
        shortDescription
        description
        price
        specialPrice
        photos
        visible
        showInHomePage
      }
      total
    }
  }
`

const GET_PRODUCT_DETAIL = `
  query getProductDetails ($productId: String) {
    getProductDetails (productId: $productId) {
      _id
      name
      categories
      shortDescription
      description
      price
      specialPrice
      photos
      visible
    }
  }
`

const GET_BANNERS = `
  query getBanners($skip: Int, $limit: Int, $keyword: String, $visible: Boolean) {
    getBanners(skip: $skip, limit: $limit, keyword: $keyword, visible: $visible) {
      banners {
        _id
        name
        image
        visible
      }
    }
  }
`

module.exports = {
  AUTH_USER,
  SOCIAL_LOGIN,
  GET_USER_DETAILS,
  GET_STASTICS_DATA,
  GET_MUSCLE_GROUPS,
  GET_EXERCISES,
  GET_EXERCISE_DETAIL,
  GET_MEASUREMENTS,
  GET_USER_MEASUREMENTS,
  GET_NOTIFICATIONS,
  UNREAD_NOTIFICATION_COUNT,
  GET_LEADER_BOARD_EXERCISES,
  GET_LEADER_BOARD_DATA,
  GET_WORKOUT_EXERCISES,
  GET_WORKOUTS,
  GET_WORKOUT_DETAILS,
  GET_SESSIONS,
  GET_FEEDS,
  GET_FEED_DETAIL,
  GET_ACTIVITIES,
  GET_USER_WORKOUT_EXERCISES,
  GET_EXERCISES_STATISTICS,
  GET_SETTING_DETAILS,
  GET_CATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCT_DETAIL,
  GET_BANNERS,
  LOGOUT
}
