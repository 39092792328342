import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  reloadPage () {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex h-100 w-100 align-items-center justify-content-center position-fixed">
          Loading ...! Please wait.
          {this.reloadPage()}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
