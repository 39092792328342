import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
	render() {
		return (
      <div className="container">
        <div className="clearfix py-5">
          <h2 className="text-center py-5">
            Oops, an error has occurred.<br/>
						Page not found!
          </h2>
          <p className="text-center">
            <Link className="btn btn-primary btn-mt-primary" to="/">Go to Home</Link>
          </p>
        </div>
      </div>
		)
	}
}

export default NotFound;
