import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import api from '@src/lib/services/api'
import actions from './actions';

export function* settingDetails() {
  yield takeEvery('SETTING_DETAILS', function*({ payload }) {
    try {
      let result = yield call(api.getSettingDetails, {})
      if (result && result.data && result.data.data && result.data.data.getSettingDetails) {
        const settingData = result.data.data.getSettingDetails
        const settings = {}
        settings.address = settingData?.address || null
        // settings.colors = { primary: settingData?.colors?.primary || '#f8cf2c', secondary: settingData?.colors?.secondary || '#939393', tertiary: settingData?.colors?.tertiary || '#363636'}
        settings.email = settingData?.email || ''
        settings.mobile = settingData?.mobile || ''
        settings.about = settingData?.about || { text: '', mission: '', vision: '' }
        // settings.name = settingData?.name || ''
        // settings.primaryLogo = settingData?.primaryLogo || ''
        // settings.secondaryLogo = settingData?.secondaryLogo || ''
        // settings.secondarySmallLogo = settingData?.secondarySmallLogo || ''
        // settings.privacy = settingData?.privacy || ''
        // settings.terms = settingData?.terms || ''

        // if(settingData?.colors?.primary) document.documentElement.style.setProperty('--primary-color', settingData?.colors?.primary)
        // if(settingData?.colors?.secondary) document.documentElement.style.setProperty('--secondary-color', settingData?.colors?.secondary)
        // if(settingData?.colors?.tertiary) document.documentElement.style.setProperty('--tertiary-color', settingData?.colors?.tertiary)
        yield put({
          type: actions.SET_SETTING_DETAILS,
          settings
        });
      }
    } catch (e) {
      console.log(e)
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(settingDetails),
  ]);
}
