import AWS from 'aws-sdk';
import { getFileName } from '@src/lib/helpers/utility';

AWS.config.update({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY });
AWS.config.region = process.env.REACT_APP_AWS_REGION;
export const s3Obj = new AWS.S3({ params: { Bucket: process.env.REACT_APP_AWS_BUCKET } });

const FileUpload = {
  fileUpload: (file, key, cb) => {
    if(file) {
      //Update file name
      const fileName = getFileName(file, key)
      //
      var params = { 'ACL': 'private', Key: fileName, ContentType: file.type, Body: file, ServerSideEncryption: 'AES256' };
      s3Obj.upload(params, function(err, data) {
        if(err) {
          // There Was An Error With Your S3 Config
          // console.log('err.message', err.message)
          cb(err.message, null)
        } else {
          cb(null, data.Location)
        }
      })
    }
  }
}

export default FileUpload;
