const ADD_USER = `
  mutation addUser($firstName: String, $lastName: String, $email: String, $password: String, $sex: String) {
    addUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password, sex: $sex) {
      _id
      email
      profile {
        firstName
        lastName
        sex
      }
    }
  }
`;

/* Update User */
const UPDATE_USER = `
  mutation updateUser($firstName: String, $lastName: String, $email: String, $sex: String, $passcode: Int, $age: Int, $weight: Int, $height: heightInput, $image: String) {
    updateUser(firstName: $firstName, lastName: $lastName, email: $email, sex: $sex, passcode: $passcode, age: $age, weight: $weight, height: $height, image: $image) {
      _id
      email
      role
      profile {
        firstName
        lastName
        age
        sex
        image
      }
      tour
      approved
    }
  }
`

/* Change password */
const CHANGE_PASSWORD = `
  mutation changePassword($oldPassword: String, $newPassword: String) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

/* Change passcode */
const CHANGE_PASSCODE = `
  mutation changePasscode($oldPasscode: Int, $newPasscode: Int) {
    changePasscode(oldPasscode: $oldPasscode, newPasscode: $newPasscode)
  }
`

/* Add user measurement */
const ADD_USER_MEASUREMENT = `
  mutation addUserMeasurement($addedAt: Date, $measurements: [measurementInput], $message: String) {
    addUserMeasurement(addedAt: $addedAt, measurements: $measurements, message: $message) {
      _id
      userId {
        _id
        profile {
          firstName
          lastName
          age
          sex
          image
        }
      }
      name
      addedAt
    }
  }
`

/* Add workout */
const ADD_WORKOUT = `
  mutation addWorkout($exercises: [workoutExerciseInput], $sessionId: ID, $duration: String) {
    addWorkout(exercises: $exercises, sessionId: $sessionId, duration: $duration) {
      _id
      userId
      exercises {
        exerciseId
      }
      sessionId
    }
  }
`

/* Add feed comment */
const ADD_FEED_COMMENT = `
  mutation addFeedComment($feedId: ID, $userId: ID, $text: String) {
    addFeedComment(feedId: $feedId, userId: $userId, text: $text) {
      userId {
        _id
        profile {
          firstName
          lastName
          image
        }
      }
      createdAt
      text
    }
  }
`

/* Like user feed*/
const LIKE_USER_FEED = `
  mutation likeUserFeed($feedId: ID, $userId: ID) {
    likeUserFeed(feedId: $feedId, userId: $userId)
  }
`

/* Read notification */
const READ_NOTIFICATIONS  = `
  mutation readNotifications {
    readNotifications
  }
`

/* Forgot password email*/
const FORGOT_PASSWORD_EMAIL = `
  mutation forgotPasswordEmail($email: String) {
    forgotPasswordEmail(email: $email)
  }
`

/* Add contact */
const ADD_CONTACT = `
  mutation addContact($name: String, $email: String, $mobile: String, $subject: String, $message: String, $type: String) {
    addContact(name: $name, email: $email, mobile: $mobile, subject: $subject, message: $message, type: $type) {
      _id
    }
  }
`

module.exports = {
  ADD_USER,
  UPDATE_USER,
  CHANGE_PASSWORD,
  CHANGE_PASSCODE,
  ADD_USER_MEASUREMENT,
  ADD_WORKOUT,
  ADD_FEED_COMMENT,
  LIKE_USER_FEED,
  READ_NOTIFICATIONS,
  FORGOT_PASSWORD_EMAIL,
  ADD_CONTACT
}
