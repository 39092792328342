import React from "react";
import { Link } from "react-router-dom";
import toastr from "toastr";
import Api from "@src/lib/services/api";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      contact: { name: "", email: "", phoneNo: "", message: "" },
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { contact } = this.state;
    if (contact && contact.name && contact.email) {
      this.setState({ loader: true });
      const instance = this;
      try {
        const data = {
          name: contact.name,
          email: contact.email,
          mobile: contact.phoneNo,
          message: contact.message,
          type: 'quote'
        };
        Api.addContact(data, (err, result) => {
          this.setState({ loader: false });
          if (
            result &&
            result.data &&
            result.data.data &&
            result.data.data.addContact &&
            result.data.data.addContact._id
          ) {
            this.setState({
              contact: { name: "", email: "", phoneNo: "", message: "" },
            });
            return toastr.success("Message send successfully");
          } else
            return toastr.error("Unable to send request..please try again");
        });
      } catch (e) {
        this.setState({ loader: true });
      }
    }
  };

  handleChange = (e) => {
    const instance = this;
    const { contact } = instance.state;
    contact[e.target.name] = e.target.value;
    instance.setState({ contact });
  };

  render() {
    const { contact, loader } = this.state;
    const { settings } = this.props;
    const siteInfo = [
      {
        title: "Address",
        text: settings?.address?.address1 || "",
        text2: settings?.address?.address2 || "",
        text3: `${settings?.address?.city || ""} ${
          settings?.address?.zipCode ? "-" : ""
        } ${settings?.address?.zipCode || ""} ${
          settings?.address?.country ? "," : ""
        } ${settings?.address?.country || ""}`,
        icon: "fa fa-home",
      },
      { title: "Mobile", text: settings?.mobile || "", icon: "fa fa-phone" },
      { title: "Email", text: settings?.email || "", icon: "fa fa-envelope" },
    ];
    return (
      <footer>
        <div className="footer-inner">
          <div className="container">
            <div className="row h-100">
              <div className="col-lg-3 footer-left">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "/img/logo.svg"}
                      alt="Logo"
                      className="logo"
                    />
                  </Link>
                </div>
                <div className="info-text text-gray my-4">
                  We are providing best services at very best prices.
                </div>
                <div className="d-flex align-items-center">
                  <a
                    target="_blank"
                    href=""
                    rel="noopener noreferrer"
                    className="text-black social-icon facebook"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@chalodiyanikul"
                    rel="noopener noreferrer"
                    className="text-black social-icon youtube"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 footer-center">
                <div className="contact-section">
                  <h1 className="contact-title pb-2 text-uppercase text-black">
                    GET A QUOTE
                  </h1>
                  <form
                    className="mt-4"
                    onSubmit={(event) => this.handleSubmit(event)}
                    id="contactForm"
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={contact.name}
                        onChange={this.handleChange}
                        placeholder="Your Name (Required)"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={contact.email}
                        onChange={this.handleChange}
                        placeholder="Your Email (Required)"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNo"
                        value={contact.phoneNo}
                        onChange={this.handleChange}
                        placeholder="Your Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control message-box"
                        name="message"
                        value={contact.message}
                        onChange={this.handleChange}
                        rows="5"
                        placeholder="Your Requirements"
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={loader}
                        className="btn btn-mt-primary btn-mt-md contact-btn"
                      >
                        REQUEST A QUOTE
                      </button>
                    </div>
                  </form>
                </div>
                <div className="info-text text-center copyright-text">
                  © 2022 Prakriti Food. Created by <a href="https://www.mahardhi.com" target="_blank" className="text-mt-primary">Mahardhi Technologies.</a>
                </div>
              </div>
              <div className="col-lg-3 footer-right">
                <ul className="list-unstyled">
                  {siteInfo.map((info, index) => (
                    <li className="pb-5" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="info-icon-wrapper">
                          <i className={info.icon}></i>
                        </div>
                        <div className="">
                          <div className="company-info-text">{info.title}:</div>
                          <div className="company-info-text font-weight-normal">
                            {info.text}
                            <span>{info.text2}</span>
                            <span>{info.text3}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.App.settings,
});

export default connect(mapStateToProps, null)(Footer);
