const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (data, token, displayMsg) => ({
    type: actions.LOGIN_REQUEST,
    payload: { data, token, displayMsg },
  }),
  logout: (isLogoutAPI) => ({
    type: actions.LOGOUT,
    payload: { isLogoutAPI }
  }),
};
export default actions;
