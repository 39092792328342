import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, /*Redirect,*/ Switch } from 'react-router-dom'
import { Provider/*, useSelector*/ } from 'react-redux'
import MetaTags from 'react-meta-tags'
import ErrorBoundary from './ErrorBoundary'
import { store } from '../redux/store'
import appAction from '@src/redux/app/actions';

// import BlankLayout from './BlankLayout'
import WebLayout from './WebLayout'
// import AdminLayout from './AdminLayout'
//
import Api from '@src/lib/services/api';
import Loader from '@src/components/utility/loader';
import NotFound from '@src/views/NotFound'

const Home = lazy(() => import('@src/views/Home'))
const Contact = lazy(() => import('@src/views/Contact'))
const About = lazy(() => import('@src/views/About'))
const Products = lazy(() => import('@src/views/Products'))
const ProductDetail = lazy(() => import('@src/views/ProductDetail'))

// Account / Admin
// const AdminDashboard = lazy(() => import('@src/admin/views/Account'))

// function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
//   const isLoggedIn = useSelector((state) => state.Auth.idToken)
//   return (
//     <Route {...rest} render={props =>
//         isLoggedIn ? (
// 					<Layout {...props}>
// 						<Component {...props} />
// 					</Layout>
//         ) : (
//           <Redirect to={{ pathname: '/admin/signin', state: { from: props.location } }} />
//         )
//       }
//     />
//   );
// }


const AppRoute = ({ component: Component, layout: Layout, ...rest }) => { // eslint-disable-line
  const title = 'Prakriti Home | supercharging the job hunt | Job Search, Pitches & Analytics', description = 'Your all-in-one tool for a supercharged job hunt! Organize your job search, create custom job pitches, and monitor how your applications are being viewed.', keyword = 'sizigi,eportfolio,custom presentations,digital resume'
  return (
    <Route {...rest} render={props => (
      <Layout {...props}>
        <MetaTags>
          <title>{rest.metaData && rest.metaData.title ? rest.metaData.title : title}</title>
          <meta name="description" content={rest.metaData && rest.metaData.description ? rest.metaData.description : description} />
          <meta name="keywords" content={rest.metaData && rest.metaData.keywords ? rest.metaData.keywords : keyword} />
          {/* */}
          <meta property="og:title" content={rest.metaData && rest.metaData.title ? rest.metaData.title : title} />
          <meta itemProp="name" content={rest.metaData && rest.metaData.title ? rest.metaData.title : title} />
          <meta property="twitter:title" content={rest.metaData && rest.metaData.title ? rest.metaData.title : title} />
          {/* */}
          <meta property="og:description" content={rest.metaData && rest.metaData.description ? rest.metaData.description : description} />
          <meta itemProp="description" content={rest.metaData && rest.metaData.description ? rest.metaData.description : description} />
          <meta name="twitter:description" content={rest.metaData && rest.metaData.description ? rest.metaData.description : description} />
        </MetaTags>
        <Component {...props} />
      </Layout>
    )} />
  )
}

class App extends React.Component {
	constructor () {
		super()
    this.state = {
			isLoading: false,
			seoTags: []
    }
	}

	UNSAFE_componentWillMount() {
    const instance = this
    store.dispatch(appAction.settingDetails());
    // try {
    //   Api.webSeoTags({}, (err, result) => {
    //     if (err) instance.setState({ isLoading: false })
    //     else {
    //       instance.setState({ isLoading: false })
    //       if (result && result.data && result.data.seoTags) {
    //         instance.setState({ seoTags: result.data.seoTags })
    //       }
    //     }
    //   })
    // } catch (e) {
    //   console.log(e)
    //   instance.setState({ isLoading: false })
    // }
  }

  getMetaData = (pageName) => {
    const { seoTags } = this.state
    let title = '', description = '', keywords = ''
    if(seoTags && seoTags.length) {
      const data = seoTags.find((tag) => tag.pageName === pageName)
      title = data && data.title ? data.title : ''
      description = data && data.description ? data.description : ''
      keywords = data && data.keyword && data.keyword.length > 0 ? data.keyword.join() : ''
    }
    return { title, description, keywords }
  }

  render() {
    const { isLoading } = this.state
    if(isLoading) return <Loader />
    return(
      <Provider store={store}>
        <>
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Router>
                <Switch>
                  {/*<Route exact path="/">
                    <Redirect to="/register" />
                  </Route>*/}
                  <AppRoute exact path="/" layout={WebLayout} component={Home} metaData={this.getMetaData('Home Page')} />
                  <AppRoute exact path="/contact" layout={WebLayout} component={Contact} metaData={this.getMetaData('Contact us')} />
                  <AppRoute exact path="/about" layout={WebLayout} component={About} metaData={this.getMetaData('About us')} />
                  <AppRoute exact path="/products" layout={WebLayout} component={Products} metaData={this.getMetaData('Products Page')} />
                  <AppRoute exact path="/product-detail/:_id" layout={WebLayout} component={ProductDetail} metaData={this.getMetaData('Product Detail')} />

                  {/*<PrivateRoute exact path="/account" layout={AdminLayout} component={AdminDashboard} />*/}

                  <Route path="*" component={NotFound} />
                </Switch>
              </Router>
            </Suspense>
          </ErrorBoundary>
        </>
      </Provider>
    )
  }
}

export default App;
