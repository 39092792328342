import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { getUrl } from '@src/lib/helpers/url_sync';
import contact from '@src/assets/images/contact.svg';
import { connect } from 'react-redux'

class Header extends React.Component {
	constructor () {
		super()
		this.state = {
			loader: false,
			menuOpen: false
		}
	}
	componentDidUpdate() {
		// this.setState({ menuOpen: false })
	}

	toggleMenu = (event) => {
		event.preventDefault()
		const { menuOpen } = this.state
		this.setState({ menuOpen: !menuOpen })
		if(!menuOpen) $('body').addClass('active')
		else $('body').removeClass('active')
	}

	render() {
		const { menuOpen } = this.state
		const currentPath = this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
		const { settings } = this.props
		//
		return (
			<header>
				<div className={`container ${currentPath !== '/' ? 'header-border' : ``}`}>
					<div className="d-lg-flex d-none justify-content-between align-items-center header-top">
						<span className={`welcome-text ${currentPath !== '/' ? 'customer-text' : ``}`}>Welcome to Prakriti Food</span>
						<div className="d-flex align-items-center">
							<a target="_blank" href="" rel="noopener noreferrer" className={`text-white social-icon ${currentPath !== '/' ? 'header-social-icon' : ``}`}><i className="fa fa-facebook"></i></a>
							<a target="_blank" href="https://www.youtube.com/@chalodiyanikul" rel="noopener noreferrer" className={`text-white social-icon ${currentPath !== '/' ? 'header-social-icon' : ``}`}><i className="fa fa-youtube-play"></i></a>
						</div>
					</div>
					<div className={`d-flex justify-content-center align-items-center header-inner  position-relative ${currentPath !== '/' ? 'header-inner-page' : ``}`}>
						<div className="logo">
							<Link to="/"><img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="Logo" className="logo" /></Link>
						</div>
						<div className="nav-links d-none d-lg-block">
							<Link to="/" className={`nav-item ${currentPath === '/' && `active`}`}>Home</Link>
							<Link to="/about" className={`nav-item ${currentPath === '/about' && `active`}`}>About</Link>
							<Link to="/products" className={`nav-item ${currentPath === '/products' && `active`}`}>Products</Link>
							<Link to="/gallery" className={`nav-item d-none ${currentPath === '/gallery' && `active`}`}>Gallery</Link>
							<Link to="/contact" className={`nav-item ${currentPath === '/contact' && `active`}`}>Contact Us</Link>
						</div>
						<div className="ml-auto">
							<div className="d-lg-flex align-items-center d-none">
								<div className="contact-wrapper">
									<img src={contact} alt="contact" className="img-fluid contact-icon" />
								</div>
								<div>
									<div className="info-text text-gray">Call Us:</div>
									<div className="info-text">{settings?.mobile || ''}</div>
								</div>
							</div>
							<div className="d-block d-lg-none">
								<button className="btn text-black btn-menu" onClick={this.toggleMenu}>
									<i className="fa fa-bars"></i>
								</button>
							</div>
						</div>
					</div>
					{/* */}
					<div className={"menu-container d-block d-lg-none" + (menuOpen ? ' show-menu' : '')}>
						<div className="menu-close">
							<span onClick={this.toggleMenu}><i className="fa fa-close" /></span>
						</div>
						<div className="clearfix">
							<div className="nav-links pt-2">
								<div className="py-3"><Link to="/" className={`nav-item ${currentPath === '/' && `active`}`}>Home</Link></div>
								<div className="py-3"><Link to="/about" className={`nav-item ${currentPath === '/about' && `active`}`}>About</Link></div>
								<div className="py-3"><Link to="/products" className={`nav-item ${currentPath === '/products' && `active`}`}>Products</Link></div>
								<div className="py-3"><Link to="/gallery" className={`nav-item ${currentPath === '/gallery' && `active`}`}>Gallery</Link></div>
								<div className="py-3"><Link to="/contact" className={`nav-item ${currentPath === '/contact' && `active`}`}>Contact Us</Link></div>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

const mapStateToProps = (state) => ({
  settings: state.App.settings,
});

export default connect(mapStateToProps, null)(Header);
